import React, { useState } from "react"
import { Container } from "reactstrap"
import { graphql, useStaticQuery } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"
import styled from "styled-components"
import Image from "gatsby-image"
import { isBrowser } from "react-device-detect"
import { Tooltip } from "reactstrap"

import links from "../../constants/links"

import StyledHero from "../StyledHero"
import Title from "../Title"
import Banner from "../Banner"

const getBackground = graphql`
  query {
    perform: file(relativePath: { eq: "perform.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    schafkopf: file(relativePath: { eq: "schafkopf-icon.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    playStore: file(relativePath: { eq: "playstore.png" }) {
      childImageSharp {
        fixed(width: 128) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    iosStore: file(relativePath: { eq: "iosstore.png" }) {
      childImageSharp {
        fixed(width: 128) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    schaf1: file(relativePath: { eq: "schafkopf1.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    schaf2: file(relativePath: { eq: "schafkopf4.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    schaf3: file(relativePath: { eq: "schafkopf3.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    schaf4: file(relativePath: { eq: "schafkopf4.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Schafkopf = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const image = useStaticQuery(getBackground)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  return (
    <StyledSection id={links[2].id}>
      <StyledHero>
        <StyledBanner
          style={styled.imageWrapper}
          image={
            <Image
              fixed={image.schafkopf.childImageSharp.fixed}
              alt="schafkopf logo"
            />
          }
          subtitle="Schafkopf Abrechner"
        ></StyledBanner>
      </StyledHero>
      <Container className="py-5 pt-xl-0 pb-xl-5">
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <div className="d-xl-none">
            <Title title={links[2].text} />
            <hr />
          </div>

          <div className="text-center my-5">
            <div>Effizenz | 55 MB | USK 0</div>
            <div className="d-flex justify-content-center">
              <div className="mr-2">
                <a
                  href="https://play.google.com/store/apps/details?id=de.heftigeapps.schafkopf_calculator"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    fixed={image.playStore.childImageSharp.fixed}
                    alt="play store"
                  />
                </a>
              </div>
              <div>
                <div id="TooltipExample">
                  <Image
                    fixed={image.iosStore.childImageSharp.fixed}
                    alt="ios store"
                  />
                </div>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  target="TooltipExample"
                  toggle={toggle}
                >
                  Bald erhältlich
                </Tooltip>
              </div>
            </div>
            <div className="d-flex justify-content-center flex-column flex-xl-row">
              <div className="mr-2">
                <Image
                  fixed={image.schaf1.childImageSharp.fixed}
                  alt="ios store"
                />
              </div>
              <div className="mr-2">
                <Image
                  fixed={image.schaf2.childImageSharp.fixed}
                  alt="ios store"
                />
              </div>
              <div className="mr-2">
                <Image
                  fixed={image.schaf3.childImageSharp.fixed}
                  alt="ios store"
                />
              </div>
              <div className="mr-2">
                <Image
                  fixed={image.schaf4.childImageSharp.fixed}
                  alt="ios store"
                />
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <div>
            <h3>Über diese App</h3>
            <p>
              Diese App ist dein Companion beim Schafkopfen mit Freunden.
              Erhalte immer den Überblick über dein Geld in deinem
              Spielgeldteller. Zudem erhältst du Statistiken über deine
              Abrechnungen
            </p>
            <h3>Features</h3>
            <ul>
              <li>
                Erhalte immmer den Überblick über dein Geld im Spielgeldteller
              </li>
              <li>Habe immer eine Übersicht über deine Gewinne und Verluste</li>
              <li>Bekomme Statistiken mit Grafiken</li>
            </ul>
            <h3>
              Pro Features <small>(Noch in der Entwicklung)</small>
            </h3>
            <ul>
              <li>Eigenes User Profil</li>
              <li>
                Erstellen von Gruppen für die Schafkopfrunde mit Leaderboard
              </li>
              <li>Weitere Statistiken und Grafiken</li>
              <li>Vergleichen der Statistiken mit deinen Freunden</li>
              <li>Exportieren und Importieren deiner Daten per CSV oder PDF</li>
            </ul>
          </div>
        </ScrollAnimation>
      </Container>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  margin-top: ${isBrowser && "-100px"};
  padding-top: ${isBrowser && "100px"};
  li {
    margin-left: 20px;
  }
`

const StyledBanner = styled(Banner)`
  .imageWrapper {
    margin-bottom: 0;
  }
`

export default Schafkopf
