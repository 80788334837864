import React from "react"

import "../utils/smothScroll"
import "../utils/resizer"

import Layout from "../components/layout"

import Intro from "../components/Intro/Intro"
import SEO from "../components/SEO"
import Apps from "../components/Apps/Apps"
import Schafkopf from "../components/Schafkopf/Schafkopf"

export default ({ path }) => {
  return (
    <Layout path={path}>
      <SEO title="Home" keywords={[`Apps, Schafkopf, Heftige`]} />
      <Intro />
      <Apps />
      <Schafkopf />
    </Layout>
  )
}
