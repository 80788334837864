import React from "react"
import Title from "../Title"
import {
  Container,
  Card,
  CardTitle,
  CardText,
  CardDeck,
  CardBody,
  CardFooter,
} from "reactstrap"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import OutlineButton from "../OutlineButton"
import { isBrowser } from "react-device-detect"
import links from "../../constants/links"

const getpicture = graphql`
  query {
    schafkopf: file(relativePath: { eq: "schafkopf-icon.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    work: file(relativePath: { eq: "work.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Apps = () => {
  const picture = useStaticQuery(getpicture)
  return (
    <StyledSection id={links[1].id}>
      <Container className="py-5">
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <Title title="Apps" />
          <hr />
        </ScrollAnimation>
        <CardDeck>
          <Card>
            <div className="text-center">
              <Image fixed={picture.schafkopf.childImageSharp.fixed} />
            </div>
            <CardBody>
              <CardTitle>
                <strong>Schafkopf Abrechner</strong>
              </CardTitle>
              <CardText>
                Diese App ist dein Companion beim Schafkopfen mit Freunden.
                Erhalte immer den Überblick über dein Geld in deinem
                Spielgeldteller. Zudem erhältst du Statistiken über deine
                Abrechnungen.
              </CardText>
            </CardBody>
            <CardFooter>
              <Link to={links[2].path}>
                <OutlineButton title="Mehr" />
              </Link>
            </CardFooter>
          </Card>
        </CardDeck>
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <div className="text-center my-5">
            <p>
              Hallo und willkommen auf meiner App Infoseite. Hier werden alle
              meine entwickelten Apps kurz vorgestellt, welche Sie im Google
              Play Store oder im Apple Store downloaden können.
              <br />
              Falls Sie Fragen zu meinen Apps haben, können Sie mich gerne unter
              meiner
              <a href="mailto:t.heft@heftigewebseiten.de"> Email </a>
              kontaktieren.
            </p>
          </div>
        </ScrollAnimation>
      </Container>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  padding-top: ${isBrowser && "100px"};
  margin-top: ${isBrowser && "-100px"};
  .card-deck {
    justify-content: ${isBrowser && "center"};
  }
  .card {
    max-width: ${isBrowser && "33%"};
  }
`

export default Apps
